import React, { HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Tooltip } from 'react-tooltip';
import { Auth } from 'aws-amplify';

import Img from '@app/components/atoms/Img';
import { FlexBox } from '@app/components/atoms';
import LinkButton from '@app/components/atoms/LinkButton';
import MenuButton from '@app/components/atoms/MenuButton';
import HeaderSelect from '../HeaderSelect';
import HeaderSelectAuth from '../HeaderSelectAuth';
import HeaderButton from '@app/components/atoms/HeaderButton';

import * as Styled from './styles';
import MyTrustyNav from '../MyTrustyNav';

export interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  clickMenu: () => void;
  footerRef: any;
  lessMargin?: boolean;
  hasBgColor?: boolean;
  hideAuth?: boolean;
  wide?: boolean;
  headerSelected?: 'clients' | 'properties';
  headerSelectedIndex?: number;
  opacity?: number;
}

const Header = ({
  clickMenu,
  footerRef,
  hasBgColor,
  lessMargin,
  hideAuth,
  wide,
  headerSelected,
  headerSelectedIndex,
  opacity = 1,
  ...rest
}: HeaderProps) => {
  const router = useRouter();
  const userData: any = useSelector<any>((state) => state.user);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const userType = userData.userType || 'user';
  const subscriptionValid = !!(
    userData?.subscription?.status === 'trialing' ||
    userData?.subscription?.status === 'active'
  );

  // Function to check if the user session is valid
  const checkSession = async () => {
    try {
      // Timeout after 5 seconds if Auth.currentSession takes too long
      const session = await Promise.race([
        Auth.currentSession(),
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error('Session check timed out')), 5000),
        ),
      ]);

      const isValid = (session as any)?.isValid() || false;
      setIsLoggedIn(isValid);
      return isValid;
    } catch (error) {
      setIsLoggedIn(false);
      return false;
    }
  };

  // Check session on mount
  useEffect(() => {
    checkSession();
  }, []);

  // Set up a session check interval
  useEffect(() => {
    // Check session every 5 minutes
    const intervalId = setInterval(() => {
      checkSession();
    }, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  // Re-check session on route changes
  useEffect(() => {
    if (router && router.asPath) {
      checkSession();
    }
  }, [router?.asPath]);

  // Add an event listener for focus to check session when tab becomes active
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkSession();
      }
    };

    const handleFocus = () => {
      checkSession();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocus);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  const myTrustyToolTipText = () => {
    if (userType === 'agent') {
      return 'Manage your buyers & properties';
    }
    return 'See your criteria';
  };

  const lessMarginOverwrite = useMemo(() => {
    return router?.asPath?.includes('/my-trusty');
  }, [router]);

  const returnMyTrustyLink = () => {
    if (userType !== 'user') {
      return '/my-trusty/properties';
    }
    return '/my-trusty';
  };

  const handleMyTrusty = () => {
    router.push(returnMyTrustyLink());
  };

  return (
    <Styled.Container hasBgColor={hasBgColor} opacity={opacity}>
      <Styled.Content
        lessMargin={lessMargin || lessMarginOverwrite}
        wide={wide}
        {...rest}
      >
        <FlexBox row itemsCenter>
          <Styled.ImageContainer>
            <Link href="/" passHref>
              <Img
                src="images/logo2.png"
                layout="fill"
                objectFit="contain"
                alt="Trusty - Home Matching Network"
              />
            </Link>
          </Styled.ImageContainer>
          <Styled.ButtonsWrapper>
            {!hideAuth && isLoggedIn && (
              <MyTrustyNav
                selected={headerSelected}
                selectedIndex={headerSelectedIndex}
              />
            )}
          </Styled.ButtonsWrapper>
        </FlexBox>
        {!hideAuth && (
          <Styled.ButtonsWrapper>
            {isLoggedIn ? (
              <>
                <Styled.ButtonContainer>
                  <HeaderSelect footerRef={footerRef} />
                </Styled.ButtonContainer>
                <Styled.ButtonContainer>
                  <HeaderSelectAuth subscriptionValid={subscriptionValid} />
                </Styled.ButtonContainer>
              </>
            ) : (
              <>
                <Styled.ButtonContainer>
                  <LinkButton
                    href="/about-us"
                    underline
                    active={router?.asPath?.includes('/about-us')}
                  >
                    About Us
                  </LinkButton>
                </Styled.ButtonContainer>
                <Styled.ButtonContainer>
                  <LinkButton
                    href="/pricing"
                    underline
                    active={router?.asPath?.includes('/pricing')}
                  >
                    Pricing
                  </LinkButton>
                </Styled.ButtonContainer>
                <Styled.MarginHeaderButton>
                  <Link href="/login" passHref>
                    <HeaderButton>Log in</HeaderButton>
                  </Link>
                </Styled.MarginHeaderButton>
                <Link href="/signup" passHref>
                  <HeaderButton variant="cyan">Sign up</HeaderButton>
                </Link>
              </>
            )}
          </Styled.ButtonsWrapper>
        )}
        <Styled.MobileWrapper>
          <MenuButton onClick={clickMenu} />
        </Styled.MobileWrapper>
      </Styled.Content>

      {userData?.superUser && (
        <Styled.SuperUserContainer itemsCenter justifyCenter>
          You are super!
        </Styled.SuperUserContainer>
      )}
    </Styled.Container>
  );
};

export default Header;
