import styled from 'styled-components';

import FlexBox from '@app/components/atoms/FlexBox';
import { Text } from '@app/components/atoms';

export const NavItem = styled.a<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  height: 35px;
  background: transparent;
  cursor: pointer;
  z-index: 3;

  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  color: ${(props) => (props.active ? '#fff' : '#4f4f4f')};

  &:hover {
    color: ${(props) => (props.active ? '#fff' : '#2ba388')};
  }

  &:first-child {
    border-radius: 10px 0 0 10px;
  }
  &:last-child {
    border-radius: 0 10px 10px 0;
  }
`;

export const SelectedBox = styled.div<{ offset: number | undefined }>`
  position: absolute;
  left: ${({ offset }) => offset || 0}px;

  width: 115px;
  height: 37px;
  background: #2faf92;
  border-radius: 10px;
  transition: 0.3s;
`;

export const Container = styled(FlexBox)<{ navItems: number }>`
  width: fit-content;
  box-sizing: border-box;
  position: relative;
  background: #d9d9d9;
  border-radius: 10px;

  &:hover ${SelectedBox} {
    background: #2ba388;
  }
`;

export const BreadcrumbWrapper = styled(FlexBox)`
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 1024px) {
    align-items: flex-end;
  }
`;

export const BreadcrumbContainer = styled(FlexBox)`
  gap: 13px;
`;

export const BreadcrumbText = styled.a`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #888888;
  text-decoration: none;
`;

export const BreadCrumbChildText = styled(Text)``;
